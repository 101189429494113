// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Emitir una Factura <br /><span className="small">(Campos completos)</span></h2>
                <p>
                    Antes de proceder a emitir una factura, habrá sido necesario haber creado un <b>cliente de prueba o producción</b> y haber cargado sus certificados.
                </p>
                <p>
                    Recuerda que en facturas de prueba es necesario usar los receptores de prueba que se especifican en la sección previa.
                </p>
                <p>
                    Una vez hayas realizado una factura sencilla, puedes proceder a emitir una factura con todos los campos listados aquí para identificar diferentes validaciones y reglas del CFDI.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/cfdi/stamp/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>fecha:</b> Fecha de la factura, entre el tiempo actual y hasta 72 horas atrás, si un cliente te pide hacer la factura de hace X dias, este es el campo a modificar.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>formaPago:</b> Forma de pago de esta factura, tal como Efectivo, transferencia, débito, etc. <em><Link to="/api/catalogo/catalogo-formaspago">Consultar catálogo SAT</Link></em>.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>metodoPago:</b> Existen tres tipos de método de pago, <b>PUE:</b> Pago en única exhibición, <b>PIP:</b> Pago inicial y en parcialidades,
                        <b>PPD:</b> Pago en parcialidades o diferido.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>tipoDeComprobante:</b><code>'I' | 'E' | 'P' | 'N' | 'T'</code>, I de ingreso, E de egreso (Nota de crédito), P de Pago (Complemento Pago), N de Nómina (Complemento nómina) y T de traslado.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>lugarExpedicion:</b>Código postal del emisor de la factura, este campo sobrescribe el valor del perfil del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>serie:</b> La serie, comúnmente indica al grupo al que pertenece la factura, la serie SNG puede tener varias facturas (SNG - 01)
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>folio:</b>La secuencia dentro de la serie de esta factura, E.G., Serie SNG y Folio 01a (SNG-01a).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>moneda:</b>Clave de la moneda en que la factura se declara (USD, MXN, CAD, etc.).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>tipoCambio:</b>Valor requerido para convertir monedas que no están en MXN a pesos.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>condicionesDePago:</b> Texto libre, usado para indicaciones especiales al comprador sobre el pago.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>note:</b> Texto libre, Nota al pie del PDF, usado de manera aleatoria, aquí es donde pondrías .
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>receptor:</b> Un solo objeto que representa al receptor con las siguientes características.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.nombre:</b> Razón social del receptor de la factura.
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.rfc:</b> RFC del receptor de la factura (Ejemplo muestra RFC de venta a mostrador).
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.usoCFDI:</b> Clave del uso de lafactura. <em><Link to="/api/catalogo/catalogo-usocfdi">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>

                            <li className="accent">
                                <p>
                                    <b><span className="clear">receptor</span>.regimen: (CFDI 4.0)</b> Clave del Régimen del receptor. <em><Link to="api/catalogo/catalogo-regimenfiscal">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li className="accent">
                                <p>
                                    <b><span className="clear">receptor</span>.zip: (CFDI 4.0)</b> Código postal del Receptor, en caso de RFC genérico se usa el Lugar de Expedición del Emisor <em>Debe ser un String de 5 carácteres numéricos</em>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.email:</b> Correo del receptor, si se agrega se mandará el PDF y el XML al receptor.
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            <b>conceptos[ ]:</b> Un array de conceptos independientes que integran la factura.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.claveProdServ:</b> Clave del producto o servicio. <em><Link to="/api/catalogo/catalogo-productoservicios">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.claveUnidad:</b> Clave de la unidad de medición. <em><Link to="/api/catalogo/catalogo-claveunidad">Consultar catálogo SAT</Link></em>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.cantidad:</b> Cantidad en número entero o decimal de productos vendidos.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.descripcion:</b> Breve descripción o nombre del producto / servicio.
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.valorUnitario:</b> Costo por unidad en número entero o decimal.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.descuento:</b> Descuento en el prodúcto, se declara antes de impuestos (no incluir impuesto aquí) y es por el total de los pedidos <br />
                                    <span className="small quote">Calcular descuentos como  <span className="error">SubTotal = (valorUnitario * cantidad) - descuento</span></span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.noIdentificacion:</b> Algun texto para relacionar el concepto con inventario del vendedor, comúnmente SKU, pero puede ser cualquiera.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.valorUnitario:</b> Costo por unidad en número entero o decimal.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>conceptos.impuestos [ ]:</b> Un array de impuestos asignados a este concepto, puede incluír varios, tal como IVA retención, IVA traslado, IEPS, etc.
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.type:</b> Tipo de impuesto, iva, ieps, isr.
                                    </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.retencion:</b> Booleano que indica si el impuesto es retenido (true) o traslado (false, DEFAULT).
                                    </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.tasa</b> El valor del impuesto en tasa debe ser un número decimal representando el porcentaje (0.16 es 16%). <br />
                                            Si se utiliza <code>tasa</code> como en el ejemplo, es valor decimal del porciento.<br />
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b><span className="clear">conceptos.impuestos</span>.cuota:</b> El valor del impuesto en cuota debe ser un número absoluto en $ MXN / unidad. <br />
                                            Si se utiliza <code>cuota</code> debe ser una cantidad fija (no porcentual) del impuesto, comunmente usado en IEPS.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <b>cfdiRelacionados: </b> Objeto con la relacion entre 1 o mas facturas con esta. Comúnmente usado en Notas de crédito y pago de anticipos.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">cfdiRelacionados</span>.tipoRelacion:</b> <code>'01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09'</code>, codigo que define la relacion con las facturas de abajo y la que vas a emitir. <br />
                                    <span className="quote small">Si deseas conocer mas sobre estas claves de relación entra <Link to="#">aquí</Link></span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">cfdiRelacionados</span>.uuids:</b> Array de los UUIDs que estas relacionando cone sta factura. 
                                </p>
                            </li>
                        </ul>
                    </li>

                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>uuid: </b> El ID de la factura, la clave unica para identificar un CFDI.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>created: </b> Cuando fue creada la factura, indistinto de la fecha de la factura
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>json:</b> Representación del XML en JSON, util cuando se quiere usar un procesador de PDFs propio u alguna deconstrucción del contenido de la factura sin tener que parsear el XML.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>xml:</b> El string del XML de la factura, el cual es el CFDI en si.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken:</b> El token del cliente emisor, usado para relacionar facturas con su emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>status:</b> <code>true | false</code> True significa que la factura está vigente, false cuando la factura está cancelada.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>sandbox:</b> <code>true | false</code> Si la factura fué generada por cliente de prueba o producción, false es de producción, true es una factura de prueba.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/cfdi-cancel`} previous={`/api/referencia/cfdi-minimum`}/>
            </div>
            <div className="side">
                <CodeBlock title="CFDI Emitir" requests={cfdiScripts.maximo.requests} response={cfdiScripts.maximo.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
